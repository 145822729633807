/** @jsx jsx */
import { jsx } from 'theme-ui';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Container from '../components/Container';
import Spinner from '../components/Spinner';
import { isBrowser } from '../utils';
import * as analytics from '../utils/analytics';
import { ReturnContext } from '../returnShipment/ReturnContext';
import { STEP } from '../returnShipment/utils';
import InvalidShipment from '../returnShipment/InvalidShipment';
import ChooseMethod from '../returnShipment/ChooseMethod';
import ChoosePickup from '../returnShipment/ChoosePickup';
import ReturnPermissionCode from '../returnShipment/ReturnPermissionCode';
import SenderInfo from '../returnShipment/SenderInfo';
import Payment from '../returnShipment/Payment';
import Ready from '../returnShipment/Ready';
import ShipmentNumber from '../returnShipment/ShipmentNumber';
import Layout from '../components/layout';
import Recaptcha from '../components/Recaptcha';
import { useRef } from 'react';

export default ({ location = {}, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const locale = useSelector(state => state.session.locale);
  const { step, isLoading, loadShipment, resetFlow } = useContext(ReturnContext);
  const paths = (pageContext && pageContext.paths) || {};

  const recaptchaInstance = useRef(null);
  const shipmentNumber = isBrowser && new URLSearchParams(location.search).get('shipment');

  useEffect(() => {
    (async () => {
      if (shipmentNumber) {
        resetFlow();
        const token = await recaptchaInstance.current.executeAsync();
        loadShipment(shipmentNumber, token);
      }
    })();
  }, [shipmentNumber]);

  return (
    <Layout title={translate('returnShipment.title')} paths={paths} locale={locale} showAppBanner="paketit">
      <Recaptcha recaptchaInstance={recaptchaInstance} show={true} />
      {shipmentNumber && isLoading ? (
        <div sx={{ minHeight: '100px', position: 'relative' }}>
          <Spinner size="medium" />
        </div>
      ) : (
        <Container sx={{ maxWidth: 1024 }}>
          {(() => {
            switch (step) {
              case STEP.CHOOSE_METHOD:
                return <ChooseMethod />;
              case STEP.RETURN_PERMISSION_CODE:
                return <ReturnPermissionCode />;
              case STEP.SHIPMENT_NUMBER:
                return <ShipmentNumber />;
              case STEP.CHOOSE_PICKUP:
                return <ChoosePickup />;
              case STEP.INVALID_SHIPMENT:
                return <InvalidShipment />;
              case STEP.SENDER_INFO:
                return <SenderInfo />;
              case STEP.PICKUP_PAYMENT:
                return <Payment />;
              case STEP.READY:
                return <Ready />;
              default:
                return null;
            }
          })()}
        </Container>
      )}
    </Layout>
  );
};
