/** @jsx jsx */

import { jsx } from 'theme-ui';
import { ReturnContext } from './ReturnContext';
import { useContext, useState } from 'react';
import { PaymentSelection } from '../components/Payment';
import Recaptcha from '../components/Recaptcha';
import { useRef } from 'react';

const Payment = () => {
  const { createOrder, sender, goBack } = useContext(ReturnContext);
  const [isProcessing, setProcessing] = useState(false);
  const recaptchaInstance = useRef();

  const onPayButton = async paymentOptions => {
    if (isProcessing) {
      return;
    }
    try {
      setProcessing(true);
      const token = await recaptchaInstance.current.executeAsync();
      await createOrder(sender, paymentOptions, token);
      setProcessing(false);
    } catch (error) {
      // no-op
    }
  };

  return (
    <div>
      <Recaptcha recaptchaInstance={recaptchaInstance} show={true} />
      <PaymentSelection onPayButton={onPayButton} onBackClick={goBack} />
    </div>
  );
};

export default Payment;
