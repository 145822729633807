/** @jsx jsx */

import { Button, FullHeightColumn } from '../components';
import { useTranslate } from '../utils/getLanguage';
import BackButton from './BackButton';
import { jsx, Styled } from 'theme-ui';
import { ReturnContext } from './ReturnContext';
import { useContext } from 'react';
import { useCallback } from 'react';
import FormField from '../components/FormField';
import { withFormik, Form } from 'formik';
import * as yup from 'yup';
import { STEP } from './utils';
import RecaptchaButton from '../components/RecaptchaButton';

const ShipmentNumberForm = ({ isSubmitting, translate, values, handleSubmit, setFieldValue }) => {
  return (
    <Form
      sx={{
        maxWidth: ['100%', 500],
        mt: 4,
      }}
    >
      <FormField name="shipmentNumber" label={translate('returnShipment.shipmentNumber')} />
      <div
        sx={{
          display: 'flex',
          flexDirection: 'col',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <RecaptchaButton
          buttonText="returnShipment.checkCode"
          translate={translate}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          setFieldValue={setFieldValue}
          disabled={!values?.shipmentNumber || isSubmitting}
          sxx={{ px: 4 }}
        />
      </div>
    </Form>
  );
};

const ShipmentNumberFormik = withFormik({
  mapPropsToValues: ({ initialValue }) => ({
    shipmentNumber: initialValue || '',
  }),
  validationSchema: ({ translate }) =>
    yup.object().shape({
      shipmentNumber: yup.string().required(translate('form.requiredField')),
    }),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
  displayName: 'ShipmentNumberForm',
})(ShipmentNumberForm);

const ShipmentNumber = () => {
  const translate = useTranslate();
  const { loadShipment, returnMethod, prefilledShipmentNumber, setPrefilledShipmentNumber, goNext } = useContext(
    ReturnContext
  );

  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      if (!values?.shipmentNumber) {
        return;
      }
      setPrefilledShipmentNumber(values.shipmentNumber);
      const { canReturn, needsReturnPermissionCode } = await loadShipment(values.shipmentNumber, values.recaptcha);
      if (!canReturn) {
        setFieldError('shipmentNumber', translate('returnShipment.invalidShipmentNumber'));
      } else {
        goNext(needsReturnPermissionCode ? STEP.RETURN_PERMISSION_CODE : STEP.CHOOSE_PICKUP);
      }
    },
    [translate, returnMethod, setPrefilledShipmentNumber, goNext]
  );

  const formProps = { onSubmit, translate, initialValue: prefilledShipmentNumber };

  return (
    <FullHeightColumn>
      <BackButton />
      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('returnShipment.shipmentNumberTitle')}</Styled.h1>
      <p>
        <p>{translate('returnShipment.shipmentNumberDescription')}</p>
      </p>
      <ShipmentNumberFormik {...formProps} />
    </FullHeightColumn>
  );
};

export default ShipmentNumber;
