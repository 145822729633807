/** @jsx jsx */

import { Button, FullHeightColumn } from '../components';
import { useTranslate } from '../utils/getLanguage';
import BackButton from './BackButton';
import { jsx, Styled } from 'theme-ui';
import { ReturnContext } from './ReturnContext';
import { useContext } from 'react';
import { useCallback } from 'react';
import FormField from '../components/FormField';
import { withFormik, Form } from 'formik';
import * as yup from 'yup';

const ReturnCodeForm = ({ setFieldValue, isSubmitting, translate, values }) => {
  const handleInputChange = useCallback(
    e => {
      const { value } = e.target;
      setFieldValue('code', value.toUpperCase().replace(/[^A-Z0-9]/g, '')); // remove non-alpha numeric characters
    },
    [setFieldValue]
  );

  return (
    <Form
      sx={{
        maxWidth: ['100%', 500],
        mt: 4,
      }}
    >
      <FormField name="code" onChange={handleInputChange} label={translate('returnShipment.enterCode')} />
      <div
        sx={{
          display: 'flex',
          flexDirection: 'col',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Button sx={{ px: 4 }} type="submit" disabled={!values?.code || isSubmitting}>
          {translate('returnShipment.checkCode')}
        </Button>
      </div>
    </Form>
  );
};

const ReturnCodeFormik = withFormik({
  mapPropsToValues: ({ initialValue }) => ({
    code: initialValue || '',
  }),
  validationSchema: ({ translate }) =>
    yup.object().shape({
      code: yup.string().min(3, translate('discount.tooShort')),
    }),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
  displayName: 'ReturnCodeForm',
})(ReturnCodeForm);

const ReturnPermissionCode = () => {
  const translate = useTranslate();
  const { checkCode } = useContext(ReturnContext);

  const onSubmit = useCallback(
    async (values, { setFieldError }) => {
      const errorCode = await checkCode(values.code);
      if (errorCode) {
        setFieldError('code', translate('returnShipment.invalidCode'));
      }
    },
    [translate]
  );

  const formProps = { onSubmit, translate, initialValue: '' };

  return (
    <FullHeightColumn>
      <BackButton />
      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('returnShipment.returnCodeTitle')}</Styled.h1>
      <p>{translate('returnShipment.returnCodeDescription')}</p>
      <ReturnCodeFormik {...formProps} />
    </FullHeightColumn>
  );
};

export default ReturnPermissionCode;
