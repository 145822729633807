/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { Button } from '../components';
import { useTranslate } from '../utils/getLanguage';
import { useContext } from 'react';
import { ReturnContext } from './ReturnContext';
import { useCallback } from 'react';

const BackButton = ({ onClick }) => {
  const { canGoBack, goBack } = useContext(ReturnContext);
  const translate = useTranslate();

  const handleGoBack = useCallback(() => {
    if (onClick) {
      onClick();
    } else if (canGoBack) {
      goBack();
    } else {
      window.history.back();
    }
  }, [canGoBack, goBack, onClick]);
  return (
    <Box>
      <Button onClick={handleGoBack} variant="plain" sx={{ color: 'primary' }}>
        {translate('backButton')}
      </Button>
    </Box>
  );
};

export default BackButton;
