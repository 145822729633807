/** @jsx jsx */
import { Button, FullHeightColumn } from '../components';
import { useTranslate } from '../utils/getLanguage';
import { jsx, Styled, Box, Flex } from 'theme-ui';
import { ReturnContext } from './ReturnContext';
import { useCallback } from 'react';
import { RETURN_METHODS } from './utils';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { locNavigate } from '../state/session';

const ChooseMethod = () => {
  const translate = useTranslate();
  const { shipment, setReturnMethod, returnMethod, goNext } = useContext(ReturnContext);
  const dispatch = useDispatch();

  const handleGoNext = useCallback(() => {
    if (returnMethod === RETURN_METHODS.RETURN_YOURSELF) {
      dispatch(locNavigate('/osta-paketti', ''));
    } else {
      goNext();
    }
  }, [returnMethod, shipment, goNext]);

  /*
  this method currently not needed for anonymous user, but might change
  <ReturnMethodOption
    id={RETURN_METHODS.RETURN_PERMISSION_CODE}
    name={translate('returnShipment.returnByPermissionCodeTitle')}
    description={translate('returnShipment.returnByPermissionCodeDesc')}
    onSelect={setReturnMethod}
    isSelected={returnMethod === RETURN_METHODS.RETURN_PERMISSION_CODE}
  />
  */

  return (
    <FullHeightColumn>
      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('returnShipment.title')}</Styled.h1>
      <p
        dangerouslySetInnerHTML={{
          __html: translate('returnShipment.returnStickerNote'),
        }}
        sx={{ a: { textDecoration: 'underline' } }}
      />
      <Styled.h2 sx={{ mb: 4 }}>{translate('returnShipment.otherWaysToReturn')}</Styled.h2>
      <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
        <ReturnMethodOption
          id={RETURN_METHODS.SHIPMENT_NUMBER}
          name={translate('returnShipment.returnByShipmentNumberTitle')}
          description={translate('returnShipment.returnByShipmentNumberDesc')}
          onSelect={setReturnMethod}
          isSelected={returnMethod === RETURN_METHODS.SHIPMENT_NUMBER}
        />
        <ReturnMethodOption
          id={RETURN_METHODS.RETURN_YOURSELF}
          name={translate('returnShipment.returnYourselfTitle')}
          description={translate('returnShipment.returnYourselfDesc')}
          onSelect={setReturnMethod}
          isSelected={returnMethod === RETURN_METHODS.RETURN_YOURSELF}
        />
      </div>

      <Button sx={{ alignSelf: 'flex-end', mt: 4, px: 4 }} onClick={handleGoNext} disabled={!returnMethod}>
        {translate('returnShipment.continue')}
      </Button>
    </FullHeightColumn>
  );
};

const ReturnMethodOption = ({ isSelected, id, name, description, onSelect }) => {
  return (
    <Box
      sx={{
        borderStyle: 'solid',
        borderColor: isSelected ? 'primary' : 'grayLight',
        borderWidth: 2,
        borderRadius: 1,
        py: 3,
        px: 3,
        cursor: 'pointer',
      }}
      role="button"
      onClick={() => onSelect(id)}
    >
      <Flex sx={{ flexDirection: 'column', gap: 0 }}>
        <div sx={{ fontWeight: 'medium', color: 'primary' }}>{name}</div>
        <div sx={{ color: 'black' }}>{description}</div>
      </Flex>
    </Box>
  );
};

export default ChooseMethod;
