/** @jsx jsx */

import { useDispatch, useSelector } from 'react-redux';
import { Button, FullHeightColumn } from '../components';
import { useTranslate } from '../utils/getLanguage';
import BackButton from './BackButton';
import { jsx, Styled } from 'theme-ui';
import { useCallback } from 'react';
import { locNavigate } from '../state/session';
import { useContext } from 'react';
import { ReturnContext } from './ReturnContext';

const InvalidShipment = () => {
  const translate = useTranslate();
  const { user } = useSelector(state => state.session);
  const { setShipment } = useContext(ReturnContext);
  const dispatch = useDispatch();

  const handleGoToOwnParcels = useCallback(() => {
    dispatch(locNavigate('/my-pages/incoming-parcels'));
  }, []);

  return (
    <FullHeightColumn>
      {!user && <BackButton onClick={() => setShipment({})} />}
      <Styled.h1 sx={{ color: 'primary', my: 4 }}> {translate('returnShipment.returnNotPossibleTitle')}</Styled.h1>
      <p>{translate('returnShipment.returnNotPossibleDescription')}</p>
      <p
        dangerouslySetInnerHTML={{
          __html: translate('returnShipment.returnNotPossibleNewShipment'),
        }}
        sx={{ a: { textDecoration: 'underline' } }}
      ></p>
      {user && (
        <Button sx={{ mt: 3, alignSelf: 'flex-start' }} onClick={handleGoToOwnParcels}>
          {translate('returnShipment.returnToOwnParcels')}
        </Button>
      )}
    </FullHeightColumn>
  );
};

export default InvalidShipment;
