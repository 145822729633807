/** @jsx jsx */
import { Button, FullHeightColumn, Link } from '../components';
import { useTranslate } from '../utils/getLanguage';
import { jsx, Styled, Box } from 'theme-ui';
import { ReturnContext } from './ReturnContext';
import { useCallback } from 'react';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { locNavigate } from '../state/session';

const Ready = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const { order } = useContext(ReturnContext);
  const { user } = useSelector(state => state.session);

  const handleGoToOwnParcels = useCallback(() => {
    dispatch(locNavigate('/my-pages/incoming-parcels'));
  }, []);

  return (
    <FullHeightColumn>
      <Styled.h1 sx={{ color: 'primary' }}>{translate('returnShipment.ready')}</Styled.h1>
      <p>
        {translate('returnShipment.readyText', {
          shipmentNumber: order?.return?.shipmentNumber,
          sender: order?.return?.recipient?.name,
        })}
      </p>

      <Box sx={{ bg: '#EFF0F1', borderRadius: 1, px: 3, maxWidth: 400 }}>
        <p sx={{ fontWeight: 'semibold', color: 'primary' }}>{translate('returnShipment.activationCode')}</p>
        <div sx={{ bg: 'white', display: 'inline-block', px: 3, py: 1, mb: 3 }}>
          <p sx={{ letterSpacing: 6, fontSize: 6, m: 0 }}>{order?.activationCode}</p>
        </div>
      </Box>

      <Box sx={{ bg: '#EFF0F1', borderRadius: 1, px: 3, maxWidth: 650, mt: 4 }}>
        <Styled.ol
          sx={{
            'li:not(:last-of-type)': {
              mb: 3,
            },
            color: 'black',
          }}
        >
          <li>{translate('returnShipment.steps.1')}</li>
          <li>{translate('returnShipment.steps.2')}</li>

          <li>{!!order?.pickup ? translate('returnShipment.steps.3pickup') : translate('returnShipment.steps.3')}</li>
        </Styled.ol>
      </Box>

      <Box sx={{ bg: '#EFF0F1', borderRadius: 1, px: 3, maxWidth: 650, mt: 4 }}>
        <p>{translate('returnShipment.noteToWebshop')}</p>
      </Box>

      {!order?.pickup && (
        <>
          <Link
            noTitleFix
            to={'/palvelupisteet'}
            sx={{ fontWeight: 'medium', display: 'inline-block', mt: 2, textDecoration: 'underline' }}
          >
            {translate('returnShipment.findServicePoints')}
          </Link>
          <p>{translate('returnShipment.reservationNote')}</p>
        </>
      )}

      {!!user && (
        <Button sx={{ alignSelf: 'flex-start', mt: 4, px: 4 }} onClick={handleGoToOwnParcels}>
          {translate('returnShipment.backToOwnParcels')}
        </Button>
      )}
    </FullHeightColumn>
  );
};

export default Ready;
