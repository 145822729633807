/** @jsx jsx */

import { Button, FullHeightColumn } from '../components';
import { useTranslate } from '../utils/getLanguage';
import BackButton from './BackButton';
import { jsx, Styled } from 'theme-ui';
import { ReturnContext } from './ReturnContext';
import { useContext } from 'react';
import { useCallback } from 'react';
import FormField from '../components/FormField';
import { withFormik, Form } from 'formik';
import * as yup from 'yup';
import Discount from './Discount';
import { formatPrice } from '../utils/formatters';

const SenderInfoForm = ({ isSubmitting, translate, values }) => {
  const { pickupPrice, discount } = useContext(ReturnContext);

  const servicePrice = (pickupPrice && pickupPrice.price) || 0;
  const discountAmount =
    (discount &&
      (discount.amount
        ? Math.min(servicePrice, discount.amount)
        : +((servicePrice * discount.percentage) / 100).toFixed(2))) ||
    0;
  const discountedPrice = servicePrice - discountAmount;

  return (
    <Form
      sx={{
        maxWidth: ['100%', 500],
        mt: 4,
      }}
    >
      <FormField name="senderName" label={translate('returnShipment.senderName')} />
      <FormField name="senderPhone" label={translate('returnShipment.senderPhone')} />
      <FormField name="senderEmail" label={translate('returnShipment.senderEmail')} />
      <FormField name="senderAddress" label={translate('returnShipment.senderAddress')} />
      <FormField name="senderPostalCode" label={translate('returnShipment.senderPostalCode')} />
      <FormField name="senderCity" label={translate('returnShipment.senderCity')} />
      <FormField name="remarks" label={translate('returnShipment.remarks')} />
      <Discount />
      <Styled.h3>
        {translate('returnShipment.price')}: {formatPrice(discountedPrice)}
      </Styled.h3>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'col',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Button sx={{ px: 4, mt: 4 }} type="submit" disabled={isSubmitting}>
          {translate('returnShipment.activateReturn')}
        </Button>
      </div>
    </Form>
  );
};

const SenderInfoFormik = withFormik({
  mapPropsToValues: ({
    senderName = '',
    senderPhone = '',
    senderEmail = '',
    senderAddress = '',
    senderPostalCode = '',
    senderCity = '',
    discountCode = '',
    remarks = '',
  }) => ({
    senderName,
    senderPhone,
    senderEmail,
    senderAddress,
    senderPostalCode,
    senderCity,
    discountCode,
    remarks,
  }),
  validationSchema: ({ translate }) =>
    yup.object().shape({
      senderName: yup.string().required(translate('form.requiredField')),
      senderPhone: yup.string().required(translate('form.requiredField')),
      senderEmail: yup.string().required(translate('form.requiredField')),
      senderAddress: yup.string().required(translate('form.requiredField')),
      senderPostalCode: yup.string().required(translate('form.requiredField')),
      senderCity: yup.string().required(translate('form.requiredField')),
      discountCode: yup.string(),
      remarks: yup.string(),
    }),
  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    return onSubmit(values, actions);
  },
  displayName: 'SenderInfoForm',
})(SenderInfoForm);

const SenderInfo = () => {
  const translate = useTranslate();
  const { setSender, sender, goNext } = useContext(ReturnContext);

  const onSubmit = useCallback(
    async values => {
      setSender(s => ({ ...s, ...values }));
      goNext();
    },
    [translate, sender, goNext]
  );

  const formProps = { onSubmit, translate, ...sender };

  return (
    <FullHeightColumn>
      <BackButton />
      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('returnShipment.senderInfo')}</Styled.h1>
      <p>{translate('returnShipment.activateReturnDesc')}</p>
      <SenderInfoFormik {...formProps} />
    </FullHeightColumn>
  );
};

export default SenderInfo;
