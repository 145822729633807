/** @jsx jsx */
import { Button, FullHeightColumn, Spinner } from '../components';
import { useTranslate } from '../utils/getLanguage';
import BackButton from './BackButton';
import { jsx, Styled, Box, Flex } from 'theme-ui';
import { ReturnContext } from './ReturnContext';
import React, { useCallback, useContext } from 'react';
import { CheckIcon } from '../components/Icon';
import { formatPrice } from '../utils/formatters';
import { useState } from 'react';
import Recaptcha from '../components/Recaptcha';
import { useRef } from 'react';

const ChoosePickup = () => {
  const translate = useTranslate();
  const { setSender, shipment, sender, pickupPrice, createOrder, setDiscount, goNext } = useContext(ReturnContext);

  const [submitting, setSubmitting] = useState(false);
  const recaptchaInstance = useRef();

  const handleGoNext = useCallback(async () => {
    if (sender.hasPickup) {
      setDiscount(null);
      goNext();
    } else {
      setSubmitting(true);
      const token = await recaptchaInstance.current.executeAsync();
      await createOrder({}, { paymentMethod: 'FREE' }, token);
    }
  }, [setDiscount, sender, goNext]);

  const handleChoosePickup = useCallback(
    value => {
      setSender(p => ({ ...p, hasPickup: value }));
    },
    [setSender]
  );

  return (
    <FullHeightColumn>
      <Recaptcha recaptchaInstance={recaptchaInstance} show={true} />
      <BackButton />
      <Styled.h1 sx={{ color: 'primary', my: 4 }}>{translate('returnShipment.possible')}</Styled.h1>
      <p>{translate('returnShipment.possibleDesc')}</p>
      {!shipment?.shipmentNumber || !pickupPrice?.price ? (
        <Spinner />
      ) : (
        <>
          <div sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Card
              name={translate('returnShipment.noPickupTitle')}
              description={translate('returnShipment.noPickupDesc')}
              isSelected={!sender?.hasPickup}
              onSelect={() => handleChoosePickup(false)}
            />

            <Card
              name={translate('returnShipment.pickupTitle')}
              price={pickupPrice?.price}
              description={translate('returnShipment.pickupDesc')}
              isSelected={sender?.hasPickup}
              onSelect={() => handleChoosePickup(true)}
              disabled={!pickupPrice?.price || pickupPrice?.price === -1}
            />
          </div>
          <Button sx={{ alignSelf: 'flex-end', mt: 4, px: 4 }} onClick={handleGoNext} disabled={submitting}>
            {translate(sender.hasPickup ? 'returnShipment.continue' : 'returnShipment.activateReturn')}
          </Button>
        </>
      )}
    </FullHeightColumn>
  );
};

const Card = ({ name, description, price = 0, isSelected, onSelect, disabled }) => {
  return (
    <Flex
      role="button"
      onClick={disabled ? null : onSelect}
      sx={{
        bg: isSelected ? 'primary' : 'white',
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: isSelected ? 'primary' : 'border',
        borderRadius: 1,
        color: 'inherit',
        backgroundColor: disabled ? 'grayLight' : 'white',
        lineHeight: 'normal',
        ':hover': {
          borderColor: !disabled && (isSelected ? 'primary' : 'grayLight'),
          cursor: disabled ? 'not-allowed' : 'pointer',
        },
        opacity: disabled ? 0.6 : 1,
      }}
    >
      {isSelected && (
        <Flex
          sx={{
            flex: 'none',
            alignItems: 'center',
            bg: 'primary',
            color: 'white',
            width: 20,
          }}
        >
          <CheckIcon sx={{ width: 16, ml: '2px' }} />
        </Flex>
      )}
      <Box sx={{ flex: '1 1 auto', px: 3, py: 4 }}>
        <Flex
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ fontWeight: 'medium' }}>{name}</Box>
          <Box
            sx={{
              flex: 'none',
              fontWeight: 'heading',
              color: 'primary',
            }}
          >
            {!!price && !disabled ? formatPrice(price) : ''}
          </Box>
        </Flex>
        <Box sx={{ fontSize: 1 }}>{description}</Box>
      </Box>
    </Flex>
  );
};

export default ChoosePickup;
