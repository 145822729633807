/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { isBrowser } from '../utils';
import ReCAPTCHA from 'react-google-recaptcha';

const Recaptcha = ({ onChange, show, recaptchaInstance }) => {
  const resetRecaptcha = () => {
    recaptchaInstance && recaptchaInstance.current && recaptchaInstance.current.reset();
  };

  const submit = response => {
    setTimeout(() => {
      reset();
    }, 100);
    onChange && onChange(response);
  };

  const reset = () => {
    resetRecaptcha();
  };

  return (
    <Box sx={{ mt: 3 }}>
      {isBrowser && show && (
        <ReCAPTCHA
          ref={recaptchaInstance}
          size="invisible"
          sitekey={process.env.GOOGLE_RECAPTCHA_SITE_KEY}
          onChange={submit}
          onExpired={reset}
        />
      )}
    </Box>
  );
};

export default Recaptcha;
