/** @jsx jsx */
import { Button } from '../components';
import { useTranslate } from '../utils/getLanguage';
import { jsx, Flex } from 'theme-ui';
import { ReturnContext } from './ReturnContext';
import { useCallback } from 'react';
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import * as api from '../utils/api';
import { PICKUP_SERVICE_CODE } from './utils';
import FormField from '../components/FormField';
import { useState } from 'react';

const Discount = () => {
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const { values, setFieldError, setFieldValue, setFieldTouched } = useFormikContext();
  const { setDiscount, discount } = useContext(ReturnContext);

  const handleCheckDiscount = useCallback(async () => {
    try {
      setLoading(true);
      const { discountCode: code } = values || {};
      const codeWithoutSpaces = code.replace(/ /g, '');
      if ((codeWithoutSpaces || '').length < 5) {
        setFieldTouched('discountCode', true, false);
        setFieldError('discountCode', translate('discount.tooShort'));
        return;
      }
      const discount = await api.checkDiscountCode(codeWithoutSpaces, PICKUP_SERVICE_CODE);
      if (typeof discount === 'string') {
        setFieldTouched('discountCode', true, false);
        setFieldError('discountCode', translate(`discount.${discount}`));
        return;
      }
      setDiscount({
        code: codeWithoutSpaces,
        amount: discount.amount,
        percentage: discount.percentage,
      });
    } catch {
      setFieldError('discountCode', translate(`discount.UNKNOWN_ERROR`));
    } finally {
      setLoading(false);
    }
  }, [values, setDiscount]);

  const handleRemoveDiscount = useCallback(() => {
    setDiscount(null);
    setFieldValue('discountCode', '');
  }, [setDiscount]);

  return (
    <div sx={{ mt: 4 }}>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 3,
          alignItems: 'center',
          width: '100%',
        }}
      >
        {!discount ? (
          <>
            <FormField name="discountCode" label={translate('returnShipment.discountCode')} width="100%" />
            {values?.discountCode?.length ? (
              <div>
                <Button type="button" onClick={handleCheckDiscount} disabled={loading}>
                  {translate('returnShipment.validateCode')}
                </Button>
              </div>
            ) : null}
          </>
        ) : (
          <Flex sx={{ flexDirection: 'row', gap: 3 }}>
            <p>
              {translate('returnShipment.discountCode')}: <span sx={{ fontWeight: 'semibold' }}>{discount.code}</span>
            </p>
            <Button variant="plain" type="button" onClick={handleRemoveDiscount}>
              {translate('returnShipment.removeCode')}
            </Button>
          </Flex>
        )}
      </div>
    </div>
  );
};

export default Discount;
